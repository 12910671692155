/* CSS für den Footer (Footer.tsx) */
/* Autor: [Christian Bucher] */
/* Erstellt am: [17.10.2023] */
/* || Container/Box styling */
.g-footer {
  box-sizing: border-box;
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  min-height: 200px;
  max-height: 1050px;
  margin: 0;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--footer-background);
  font-family: "Geist";
}

.g-footer__content-container {
  min-width: 40%;
  max-width: 1260px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(3, auto);
}

.g-footer__navGrid {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: baseline;
  justify-content: space-between;
}

.g-footer__navGrid__ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
}

.g-footer__navGrid__ul__item {
  margin: 0;
  padding: 0;
  font-size: var(-- font-size);
  font-weight: var(--font-weight);
}

.g-footer__navGrid__horizSep {
  height: 1px; /* Du kannst die Höhe nach Bedarf anpassen */
  width: 100%; /* Breite der Trennlinie */
  background-color: var(--secondary-text-color); /* Farbe der Trennlinie */
  margin: 25px 0px;
}

.g-footer__navGrid__ul__heading {
  color: var(--primary-text-color-dark);
  font-size: var(--font-size);
  font-weight: var(--heading-font-weight);
  margin: 0 0 15px 0;
}

.g-footer__navGrid--primaryLink {
  color: var(--primary-text-color-dark);
  text-decoration: none;
}

.g-footer__navGrid--primaryLink:hover {
  text-decoration: underline;
}

.g-footer__navGrid__ul__item__label {
  line-height: var(--line-height);
}

.g-footer__cs__info {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.g-footer__cs__info__ohGrid__ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
}

.g-footer__cs__info__ohGrid__ul__item {
  font-size: var(--small-font-size);
}

.g-footer__cs__info__ohGrid__ul__heading {
  color: var(--primary-text-color-dark);
  font-size: var(--font-size);
  font-weight: var(--heading-font-weight);
  margin: 0 0 15px 0;
}

.g-footer__cs__info__ohGrid__ul__item__label {
  line-height: var(--line-height);
}

.g-footer__cs__info__ohGrid__ul__item__payGrid {
  display: grid;
  grid-template-columns: repeat(5, auto);
}

.g-footer__copyright__content {
  margin: 0 auto;
  font-size: var(--small-font-size);
}

.g-footer__copyright {
  margin: 25px 0;
}

.g-footer__nodiaTechLink--primary {
  color: var(--secondary-color);
  text-decoration: none;
}

.g-footer__nodiaTechLink--primary:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1200px) {
  .g-footer__navGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: baseline;
    justify-content: space-between;
  }

  .g-footer__cs__info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    gap: 24px;
  }

  .g-footer__navGrid__ul__heading {
    color: var(--primary-text-color-dark);
    font-size: var(--font-size);
    font-weight: var(--heading-font-weight);
    margin: 15px 0 0 0;
  }
}
