.homepageContainer {
  height: auto;
  display: flex;
  flex-direction: column;
}

.homepageContainer__g-discount--primary {
  width: 100%;
  background-color: #121212;
  color: #fff;
  height: 65px;
  padding-top: 65px;
  position: relative;
  font-size: var(--font-size);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.homepageContainer__g-discount__label {
  margin: 0 1ch;
}

.homepageContainer__landingSection {
  display: flex;
  height: 700px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 600px;
  width: calc(100% - 50px);
  margin: 0 auto;
  gap: 25px;
}

.homepageContainer__landingSection-rev {
  display: flex;
  height: auto;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  max-width: 2560px;
  margin: 0 auto;
  gap: 25px;
}

.landing__section {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.landing__content {
  background-image: url("../../../common/assets/expImages/f10ae5df-56a2-4082-97dc-dce6963bbebf.webp");

  height: calc(100vh - 65px);

  /* Parallax-Effekt */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepageContainer__landingSection__heading--primary {
  font-size: var(--main-heading-font-size);
  width: 50%;
  color: var(--primary-text-color-dark);
  line-height: var(--line-height-heading);
  padding: 0;
  margin: 0;
  position: relative !important;
}

.homepageContainer__landingSection__locationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.homepageContainer__landingSection__locationContainer p {
  width: 50%;
  font-size: 1.2rem;
}

picture {
  width: 50%;
  height: 100%;
}

.homepageContainer__landingSection__comImage {
  width: 100%;
  height: 700px;
}
.homepageContainer__landingSection__comImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepageContainer__featured {
  height: auto;
  width: calc(100% - 48px);
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.homepageContainer__featured-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepageContainer__featured-content-sec {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepageContainer__featured-content-sec img {
  filter: brightness(60%) saturate(120%);
  object-fit: cover;
  height: 700px;
  width: 100%;
}

.homepageContainer__landingSection__locationContainer__grid {
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 15px;
}

.homepageContainer__landingSection__locationContainer__grid__card--primary {
  box-shadow: var(--box-shadow-value);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
}

.homepageContainer__landingSection__locationContainer__grid__textHolder {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.homepageContainer__landingSection__locationContainer__grid__card--primary p,
h3 {
  margin: 0;
  font-size: var(--small-font-size);
  line-height: var(--line-height);
}

.homepageContainer__landingSection__locationContainer__grid__locationImage {
  width: 200px;
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.homepageContainer__landingSection__welcomeContent__holder {
  width: 100%;
  height: 100%;
}

.homepageContainer__landingSection__welcomeContent__adImageHolder {
  width: 100%;
}

.homepageContainer__new {
  width: 100%;
  margin: 0 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.homepageContainer__fav {
  position: relative;
  background-color: var(--header-grey);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.homepageContainer__new__headingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  margin-top: 60px;
  color: white;
}

.homepageContainer__fav__headingContainer {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9996;
}
.homepageContainer__fav__headingContainer h1 {
  color: var(--primary-text-color-dark);
  text-align: center;
}
.homepageContainer__fav__headingContainer p {
  font-size: var(--heading-font-size);
  color: var(--primary-text-color-dark);
  text-align: center;
}

.homepageContainer__new__productsContainer {
  width: 100%;
  padding: 48px 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepageContainer__featured {
    height: auto;
    width: calc(100% - 16px);
    max-width: 768px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }

  .homepageContainer__featured-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .homepageContainer__featured-content h1 {
    position: relative;
    color: white;
    font-size: 1.2em;
  }
}
