.center-logo {
  width: 50px;
  margin: 0 auto;
  height: 65px;
  position: relative;
  top: 0;
  left: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .center-logo {
    width: 50px;
    margin: 0 auto;
    height: 65px;
    position: fixed;
    top: 0;
    left: calc(50% - 25px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
