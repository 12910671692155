.default-layout {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

.machine-layout__content {
  margin: 0;
  padding: 0;
  height: 100vh;
}
