.verification-container {
    padding-top: 100px;
    text-align: center;
    padding-bottom: 50px;
}

.verification-container h1 {
    margin-bottom: 20px;
}

.verification-container p {
    font-size: 1.2em;
    color: #333;
}