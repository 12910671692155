@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.pd__container {
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  margin-bottom: 64px;
  height: auto;
}

.pd__imgGallery {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pd__Slidercontainer {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  justify-content: end;
  z-index: 997;
  overflow: hidden;
  position: relative;
  height: auto;
}

.slick-slider {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.pd__slider .slick-list {
  width: 500px;
}

/* Slider-Thumbnails Styling */
.slick-dots {
  position: static;
  list-style: none; /* Entfernt List-Styling */
  padding: 0; /* Setzt Padding zurück */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  height: auto;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
}

.slick-dots li {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto; /* Setzt die Breite der Thumbnails */
}

@media screen and (min-width: 500px) {
  .slick-dots li {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto; /* Setzt die Breite der Thumbnails */
  }
}

.slick-dots li img {
  cursor: pointer; /* Zeigt einen Pointer-Cursor beim Hover über das Thumbnail */
  border: 2px solid transparent; /* Standard-Border, transparent */
  transition: border 0.3s; /* Weiche Transition für den Border */
  border-radius: 6px;
}

.slick-dots li.slick-active {
  filter: brightness(60%);
}

.slick-dots li:hover {
  filter: brightness(60%);
}

.pd__content {
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 44px;
  width: 50%;
  height: auto;
  max-width: 50%;
}

.art__content-holder {
  width: 100%;
}

.pd__artNum {
  font-size: 16px;
  color: var(--secondary-text-color);
}

.pd__category {
  font-size: 16px;
  color: var(--secondary-text-color);
}

.pd__title {
  font-size: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-background {
  position: fixed;
  top: 89px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-background img {
  max-width: 80%;
  max-height: 80%;
}

.modal-background span {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
}

.prodImage {
  object-fit: contain;
  height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdSlider__nav__holder {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 9999;
}

.pd__button-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.pd__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: auto;
  text-wrap: nowrap;
  border: none;
  background-color: var(--tertiary-color);
  color: white;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  gap: 17px;
}

.fav-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-wrap: nowrap;
  border: none;
  background-color: var(--tertiary-color);
  color: white;
  padding: 16px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  gap: 17px;
}

.fav-btn:hover svg {
  fill: #fff;
}

.p__tag {
  font-size: 24px;
  font-weight: 700;
}

article {
  background: transparent;
  width: 100%;
  display: grid;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-text-color);
}

article header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0px;
  cursor: pointer;
  background: transparent;
  color: var(--primary-text-color-dark);
}

article header.active {
  background: transparent;
  color: var(--secondary-color);
}

article header span {
  rotate: -180deg;
}

article header.active span {
  rotate: 0deg;
}

article header.active span svg {
  fill: #0b368c;
}

article h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

article p {
  margin: 0;
  padding: 20px 10px;
  line-height: 1.7;
  font-size: 16px;
}

article span {
  transition: 0.3s;
}

article header ~ div {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}

.card__details-single {
  display: flex;
  flex-direction: column;
}

.card-details__single-p {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 24px;
  font-size: 18px;
}
