@tailwind base;
@tailwind components;
@tailwind utilities;

/* -----------------------
   Farben
   ----------------------- */
:root {
    --primary-color: #f5f5f7;
    /* Hauptfarbe */
    --secondary-color: #0b368c;
    /* Sekundärfarbe */
    --tertiary-color: rgba(39, 60, 117, 1);
    --tertiary-color--secondary: rgba(39, 60, 117, 0.4);
    --primary-text-color: #e8e8ed;
    --primary-text-color-dark: #1d1d1f;
    --secondary-text-color: #6e6e73;
    /* Textfarbe */
    --footer-background: #e0e0e0;
    --header-background: rgba(34, 47, 62, 0.8);
    --header-background-full: #121212;
    --bg-grey: rgb(19, 0, 0);
    --background-color-fade: linear-gradient(180deg,
            #ecf0f1,
            #bdc3c7);
    /* Hintergrundfarbe */
    --box-shadow-value: 0px 2px 8px 2px rgba(0, 0, 0, 0.2);
    --box-shadow-value-alt: 0px 4px 16px 4px rgba(0, 0, 0, 0.2);
    --global-nav-link-color: #3a68ff;
}

:root {
    --font-size: 14px;
    /* Basisschriftgröße */
    --small-font-size: 0.8rem;
    --font-weight: 500;
    --heading-font-size: 18px;
    /* Schriftgröße für Überschriften */
    --med-heading-font-size: 16px;
    --main-heading-font-size: 36px;
    --heading-font-weight: 600;
    --custom-font: "Poppins", sans-serif;
}

/* -----------------------
       Abstände und Abmessungen
       ----------------------- */
:root {
    --spacing-unit: 8px;
    /* Abstandseinheit */
    --line-height: 1.5;
    /* Zeilenhöhe */
    --line-height-heading: 1.1;
    --border-width: 2px;
    /* Rahmenbreite */
    --border-width-thin: 0.5px;
}

/* -----------------------
       Z-Index-Werte
       ----------------------- */
:root {
    --z-index-modal: 1000;
    /* Z-Index für Modals */
    --z-index-dropdown: 500;
    /* Z-Index für Dropdowns */
}

/* -----------------------
       Medienabfragen
       ----------------------- */
:root {
    --breakpoint-small: 576px;
    /* Kleine Bildschirme */
    --breakpoint-medium: 768px;
    /* Mittlere Bildschirme */
    --breakpoint-large: 992px;
    /* Große Bildschirme */
}

/* -----------------------
       Transitions und Animationen
       ----------------------- */
:root {
    --transition-duration: 0.3s;
    /* Dauer von Übergängen */
    --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
    /* Timing-Funktion */
}

/* Globale Stile für den Seitenrahmen */
body {
    margin: 0;
    padding: 0;
    font-family: var(--custom-font);
    font-size: var(--font-size);
    color: var(--text-color);
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}