.imprint-container {
  padding-top: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ic__heading {
  width: 50%;
}

.ic__contentContainer {
  width: 50%;
}

.ic__contentContainer__list {
  list-style: none;
  padding: 0;
}

.i__link:hover {
  text-decoration: underline;
}
