@charset "UTF-8";

.header {
  width: 100%;
  height: 65px;
  position: fixed;
  z-index: 9998;
  background: rgba(18, 18, 18, 1);
  font-family: "Geist";
}

.container {
  height: 65px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@keyframes expandBanner {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 80px;
    /* Angenommene Höhe des Banners */
    opacity: 1;
  }
}

@keyframes collapseBanner {
  from {
    height: 80px;
    /* Angenommene Höhe des Banners */
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

.banner-expand {
  animation: expandBanner 0.5s ease-out forwards;
}

.banner-collapse {
  animation: collapseBanner 0.5s ease-out forwards;
}

.util-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.centerMenu {
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 225px);
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  gap: 42px;
}

.menu-container,
.menu-container *,
.menu-items-container,
.menu-items-container * {
  box-sizing: border-box;
  display: none;
}

.menu-container {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 15px);
  left: 48px;
}

.watches-container {
  cursor: pointer;
}

.menu-container .line {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 4px;
  top: 4px;
  transition: transform 300ms ease-in-out;
}

.menu-container .line-inner {
  height: 1px;
  width: 24px;
  background: var(--primary-text-color);
  border-radius: 4px;
  position: absolute;
  transition: transform 100ms ease-in-out;
  transition-delay: 200ms;
}

.menu-container .line-inner-1 {
  top: 12px;
  transform: translateY(4px);
}

.menu-container .line-inner-2 {
  bottom: 12px;
  transform: translateY(-4px);
}

.menu-container.active .line-1 {
  transform: rotateZ(45deg);
}

.menu-container.active .line-2 {
  transform: rotateZ(-45deg);
}

.menu-container.active .line-inner {
  transform: none;
  transition: transform 200ms ease-in-out;
}

.menu-container.active .line {
  transition-delay: 200ms;
}

.menu-items-container,
.menu-watches-container,
.menu-jewelery-container,
.menu-novelty-container,
.menu-profile-container {
  position: fixed;
  min-height: 400px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
  transition: clip-path 0.3s;
  background: var(--header-background-full);
  backdrop-filter: blur(24px) brightness(60%) saturate(180%);
  transition-delay: 50ms;
  z-index: 996;
}

/* .watches-container.active,
.jewelery-container.active,
.novelty-container.active {
  padding: 100vw;
  height: 50vh;
  position: absolute;
} */
.menu-items-container.active,
.menu-watches-container.active,
.menu-jewelery-container.active,
.menu-novelty-container.active,
.menu-profile-container.active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.menu-items-container a,
.menu-watches-container a,
.menu-jewelery-container a,
.menu-novelty-container a,
.menu-profile-container a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  padding: 12px 40px;
  display: inline-block;
  transition: all 200ms ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  gap: 11px;
}

.menu-items-container a:hover {
  cursor: pointer;
}

.menu-items-container ul,
.menu-watches-container ul,
.menu-jewelery-container ul,
.menu-novelty-container ul,
.menu-profile-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 450px;
  margin: 56px auto;
}

.menu-items-container ul li a,
.menu-watches-container ul li a,
.menu-jewelery-container ul li a,
.menu-novelty-container ul li a,
.menu-profile-container ul li a {
  padding: 0;
  margin-top: 14px;
}

.menu-items-container ul li a:hover,
.menu-watches-container ul li a:hover,
.menu-jewelery-container ul li a:hover,
.menu-novelty-container ul li a:hover,
.menu-profile-container ul li a:hover {
  color: rgba(232, 232, 237, 0.7);
}

.menu-items-container ul li h3,
.menu-watches-container ul li h3,
.menu-jewelery-container ul li h3,
.menu-novelty-container ul li h3,
.menu-profile-container ul li h3 {
  padding: 0;
  margin: 0;
}

.mc-li-1 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.1s, transform 0.1s;
  transition-delay: 0.1s;
}

.mc-li-2 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.15s, transform 0.15s;
  transition-delay: 0.15s;
}

.mc-li-3 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.2s, transform 0.2s;
  transition-delay: 0.2s;
}

.mc-li-4 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.25s, transform 0.25s;
  transition-delay: 0.25s;
}

.mc-li-5 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.3s;
}

.mc-li-6 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.35s, transform 0.35s;
  transition-delay: 0.35s;
}

.mc-li-7 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.4, transform 0.4;
  transition-delay: 0.4;
}

.mc-li-8 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.45, transform 0.45;
  transition-delay: 0.45;
}

.mc-li-9 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.5, transform 0.5;
  transition-delay: 0.5;
}

.mc-li-10 {
  opacity: 0;
  transform: translateY(-25px);
  /* Startet außerhalb des Containers unten */
  transition: opacity 0.55, transform 0.55;
  transition-delay: 0.55;
}

.mc-li-1.active,
.mc-li-2.active,
.mc-li-3.active,
.mc-li-4.active,
.mc-li-5.active,
.mc-li-6.active,
.mc-li-7.active,
.mc-li-8.active,
.mc-li-9.active,
.mc-li-10.active {
  opacity: 1;
  transform: translateY(0);
}

.bag__link:hover svg {
  fill: rgba(232, 232, 237, 0.7);
  stroke: rgba(232, 232, 237, 0.7);
}

.navAccHeading {
  color: #fff;
}

.navbar__funcReg {
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--global-nav-link-color);
  border-radius: 8px;
  background-color: rgba(39, 60, 117, 0.4);
  padding: 10px 24px;
  color: var(--global-nav-link-color);
  cursor: pointer;
}

@media screen and (max-width: 1200px) {

  .menu-container,
  .menu-container *,
  .menu-items-container,
  .menu-items-container * {
    box-sizing: border-box;
    display: block;
  }

  .centerMenu {
    display: none;
  }

  .profile-container {
    display: none;
  }

  .menu-items-container ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 80%;
    margin: 48px auto;
  }

  .container {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--header-background-full);
    backdrop-filter: blur(180px) brightness(60%) saturate(180%);
    padding: 0 24px;
  }

  .menu-container {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 17px);
    left: 24px;
  }
}