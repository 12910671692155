@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Geist";
  src: url("../src/assets/fonts/Geist/Geist-Regular.otf");
}

@font-face {
  font-family: "Geist-Bold";
  src: url("../src/assets/fonts/Geist/Geist-Bold.otf");
}

@font-face {
  font-family: "Geist-Light";
  src: url("../src/assets/fonts/Geist/Geist-Light.otf");
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Geist", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: white;
  /* background: linear-gradient(
    to bottom,
    rgba(250, 200, 152, 0.8),
    rgba(250, 200, 152, 0.1) 40%,
    rgba(255, 255, 255, 1) 50%
  ); */
  color: #121212;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .g-navLink {
  color: var(--global-nav-link-color);
  margin-left: 15px;
  font-size: 16px;
  font-weight: var(--heading);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.g-navLinkC {
  color: var(--global-nav-link-color);
  margin-left: 15px;
  font-size: 16px;
  font-weight: var(--heading);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.g-navLinkC:hover {
  text-decoration: underline;
}

.g-navLinkAlt {
  color: var(--global-nav-link-color);
  margin-left: 15px;
  font-size: var(--font-size);
  font-weight: var(--heading);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  width: 100%;
}

.g-buttonLink {
  color: var(--primary-text-color-dark);
  border: 2px solid var(--header-background-full);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: transparent;
  padding: 15px 25px;
  border-radius: 25px;
  font-size: 1.2em;
}

.g-buttonLinkAlt {
  color: var(--primary-text-color);
  border: 2px solid var(--primary-text-color);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: transparent;
  padding: 15px 25px;
  border-radius: 25px;
  font-size: 1.2em;
}

.g-buttonLink:hover {
  color: white;
  border: 2px solid var(--header-background-full);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: var(--header-background-full);
  padding: 15px 25px;
  border-radius: 25px;
  font-size: 1.2em;
}

.g-buttonLinkAlt:hover {
  color: var(--primary-text-color-dark);
  border: 2px solid var(--primary-text-color);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: var(--primary-text-color);
  padding: 15px 25px;
  border-radius: 25px;
  font-size: 1.2em;
}

.g-navLink::after {
  content: url("../src/common/assets/icons/navlink-arrow.svg");
}

.g-navLink:hover {
  text-decoration: underline;
}

.g-navLinkAlt {
  color: var(--primary-text-color);
  margin-left: 15px;
  font-size: var(--font-size);
  font-weight: var(--heading);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  gap: 11px;
}

.g-navLinkAlt:hover {
  color: rgba(232, 232, 237, 0.7);
}

.g-navLinkAlt:hover svg {
  stroke: rgba(232, 232, 237, 0.7);
}

.sign__btn {
  text-decoration: none;
  text-align: center;
  color: rgba(10, 61, 98, 1);
  border: 2px solid rgba(10, 61, 98, 1);
  background-color: #ddf2fd;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sign__btn:hover {
  background: #c2d9ff;
}

.btn-primary {
  border-radius: 6px;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-text-color);
  background-color: #121212;
  border: none;
  text-decoration: none;
}

.vertSep {
  height: 32px;
  width: 0.5px;
  background-color: var(--primary-text-color-dark);
  margin: 0 15px;
}

.vertSepAlt {
  height: 18px;
  width: 0.5px;
  background-color: var(--primary-text-color);
  margin: 0 10px;
} */

/* LODAING SPINNER */

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  top: 0%;
  left: 0%;
  background-color: var(--header-background);
  backdrop-filter: blur(24px) brightness(40%);
  z-index: 9999;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}