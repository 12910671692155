.uo-container {
    padding-top: 65px;
    width: 100%;
    height: 100%;
}

.uo__cardGrid-holder {
    width: 50%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    justify-content: start;
    gap: 24px;
}

@media screen and (max-width: 600px) {
    .uo__cardGrid-holder {
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: start;
        justify-content: start;
        gap: 24px;
    }
}

.uo__cardGrid {
    width: 100%;
    margin: 0 auto;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    margin-top: 60px;
    gap: 24px;
}

.uo__heading {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: baseline;
    font-size: var(--main-heading-font-size);
    font-weight: 600;
    margin-top: 60px;
}

.cardPadding {
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    gap: 24px;
}

.uo__Card {
    box-shadow: var(--box-shadow-value);
    border-radius: 16px;
    font-size: var(--heading-font-size);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    gap: 24px;
}

.uo__card__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    height: auto;
    margin: 0;
    padding: 0;
    gap: 24px;
}

.uo__card__heading h1 {
    margin: 0;
}

.uo__profileCard-l {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.uo__profileCard-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;
}

.delivery__l {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
}