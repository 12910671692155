@charset "UTF-8";
.watchesShopPage-container {
  padding-top: 30px;
}

.watchesShopPage__cat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;
}

.watchesShopPage__build-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 24px;
}

.watchesShopPage__heading--primary {
  margin-bottom: 60px;
}

.watchesShopPage__productGrid-holder {
  gap: 48px;
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.watchesShopPage__productGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Anpassung je nach gewünschter Produktgröße */
  gap: 16px; /* Abstand zwischen den Produkten */
  width: 100%;
}

.watchesShopPage__productGrid-card {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  transition: ease 0.5s;
  padding: 25px;
}

.watchesShopPage__productGrid-card:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.watchesShopPage__productGrid-card--primary {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
}
.watchesShopPage__productGrid-card--primary h3 {
  margin: 0;
}

.watchesShopPage__productGrid-heading {
  font-size: 3rem;
  margin: 0 auto;
}

.watchesShopPage__productGrid-img {
  width: 100%;
  margin: 0 auto;
}

.cardHalf {
  width: 100%;
}

.card__detials {
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-top: 17px;
}

.card-details__icon-p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  gap: 11px;
  font-size: 12px;
  margin: 5px 0;
}

.watchesShopPage__productGrid-card__mid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
}

.priceSpan {
  font-size: 16px;
}

.watchesShopPage__productGrid__sort {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  top: 24px;
  width: 200px;
}

.watchesShopPage__productGrid__sort--vert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/*
*
*
*
/* SLIDER 
*
*
*
*/
.shoppingBag__button {
  border: none;
  padding: 8px;
  margin: 0;
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  border-radius: 16px;
}

.vertSepSmall {
  height: 1.5ch;
  width: 0.5px;
  background-color: var(--primary-text-color-dark);
  margin: 0 15px;
}

.statusSpan {
  font-size: 12px;
  color: var(--secondary-text-color);
}
