.sign-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/images/bg/Union_D016.407.16.XXX.00_mood5.jpg);
  background-position: 0%;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: cover;
}

.backLink {
  position: relative;
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 8px 18px;
  border-radius: 24px;
  backdrop-filter: blur(24px);
  text-decoration: none;
}

.sign-card {
  min-height: 500px;
  height: auto;
  min-width: 480px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(24px) saturate(180%) brightness(60%);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 0px 0px rgba(0, 0, 0, 0.22);
}

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

.login-container h1 {
  font-size: 36px;
}

.form-LableInput {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.sh__icon {
  position: absolute;
  right: 8px;
  top: calc(50% - 3px);
  border: none;
  background-color: transparent;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sh__icon p {
  padding: 0;
  margin: 0;
  width: 15px;
  height: 15px;
}

.form-LableInputKeep {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-LableInputKeep a {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.form-LableInputKeep a:hover {
  color: rgba(12, 36, 97, 1);
  text-decoration: underline;
}

.keep-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sucLogSpan {
  padding: 16px;
  text-align: center;
  border: 1px solid #b0d9b1;
  color: #b0d9b1;
  background-color: #618264;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.loginForm {
  margin: 0 auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.loginInput {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #121212;
  border: 1px solid var(--secondary-text-color);
  background: rgba(242, 241, 238, 0.6);
  line-height: 1.5;
}

.loginInput:focus {
  outline: none;
  /* Hellblaue Farbe (Material UI und Apple-ähnlich) */
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.25);
}

.loginForm label {
  color: var(--secondary-text-color);
  padding: 0;
  margin: 0;
}

.sf-container {
  margin: 0 auto;
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.sf-container a {
  text-decoration: none;
  color: #121212;
  font-size: 14px;
}

.sf-container a:hover {
  color: rgba(12, 36, 97, 1);
  text-decoration: underline;
}

.reg-container {
  border-left: 1px solid var(--secondary-text-color);
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(24px) saturate(180%) brightness(60%);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  gap: 24px;
}

.reg-c__content {
  margin: 0 auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reg-container h1 {
  color: #121212;
  font-size: 36px;
  text-align: center;
}