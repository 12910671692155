.newsSlider__container {
    width: calc(100% - 296px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 997;
}

.newsSlider__container--primary {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
}

.product-slick-slider-bottom-navigation-holder {
    width: 100%;
    height: 8px 0;
    position: absolute;
    top: auto;
    bottom: auto;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.newsSlider__nav__holder--secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: auto;
    background-color: var(--secondary-text-color);
    border-radius: 24px;
    padding: 5px;
}

.newsSlider__nav__buttonHolder--secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--secondary-text-color);
    opacity: 0.8;
    padding: 10px;
    backdrop-filter: blur(25px);
}

.newsSlider__container--primary .slick-list {
    width: 100%;
    height: auto;
}

.service-card-container {
    margin: 0 auto;
    width: 100%;
}

.service-card {
    width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin: 10px 11px;
}

.service-card:hover img {
    transition: ease 0.5s;
}

.service-card:hover img {
    transform: scale(1.05);
}

.service-card span {
    font-size: var(--med-heading-font-size);
}

.service-card h3 {
    font-size: var(--heading-font-size);
    padding: 0;
    margin: 0;
}

.service-card__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding-left: 25px;
    color: var(--primary-text-color-dark);
}

.newsSlider-img-img {
    width: 300px;
    height: 400px;
    margin: 0 auto;
    padding: 25px 0;
    object-fit: contain;
}

@media screen and (max-width: 960px) {
    .newsSlider-img-img {
        width: 100px;
        height: 150px;
        margin: 0 auto;
        padding: 25px 0;
        object-fit: contain;
    }

    .newsSlider__container {
        width: calc(100% - 65px);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 997;
    }

    .service-card__bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        color: var(--primary-text-color-dark);
    }
}