/* CartComponent.css */
.modal-backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(12, 12, 12, 0.5);
  backdrop-filter: blur(5px) brightness(60%) saturate(180%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Verhindert Interaktion, wenn das Modal nicht sichtbar ist */
}

.modal-backdrop.open {
  opacity: 1;
  pointer-events: auto; /* Ermöglicht Interaktion, wenn das Modal sichtbar ist */
}

.cart-modal {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100vw; /* oder eine andere Breite */
  background-color: white;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out, clip-path 0.6s ease-in-out;
  z-index: 10000; /* Sorgt dafür, dass das Modal über dem Backdrop liegt */
  clip-path: polygon(
    100% 0,
    100% 100%,
    100% 100%,
    100% 0
  ); /* Startet von der rechten Seite */
}

.cart-modal.open {
  transform: translateX(0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0); /* Volle Anzeige */
  z-index: 10000;
}

.quantity-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;
}
