.custom-prev-arrow {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}

.custom-prev-arrow:hover {
  cursor: pointer;
}

.custom-next-arrow {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}

.custom-next-arrow:hover {
  cursor: pointer;
}

.custom-prev-arrow-alt {
  height: 48px;
  width: 48px;
  margin: 0;
  border-radius: 50%;
  background-color: rgba(45, 52, 54, 0.4);
  backdrop-filter: blur(24px) brightness(60%) saturate(180%);
  position: absolute;
  top: calc(50%);
  left: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-prev-arrow-alt:hover {
  cursor: pointer;
}

.custom-next-arrow-alt {
  height: 48px;
  width: 48px;
  margin: 0;
  border-radius: 50%;
  background-color: rgba(45, 52, 54, 0.4);
  backdrop-filter: blur(24px) brightness(60%) saturate(180%);
  position: absolute;
  top: calc(50%);
  right: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-next-arrow-alt:hover {
  cursor: pointer;
}

.custom-prev-arrow-alt-rel {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: rgba(45, 52, 54, 0.4);
  backdrop-filter: blur(24px) brightness(60%) saturate(180%);
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-prev-arrow-alt-rel:hover {
  cursor: pointer;
}

.custom-next-arrow-alt-rel {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: rgba(45, 52, 54, 0.4);
  backdrop-filter: blur(24px) brightness(60%) saturate(180%);
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-next-arrow-alt-rel:hover {
  cursor: pointer;
}
